
import React,{useEffect} from 'react';
import { Container, Row, Col, Table, ListGroup,Carousel,CarouselItem } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import './eventcard.css'



export const EventCard = ({title,img,id,date}) => {
//   useEffect(() => {
//     window.scrollTo(0, 0); // Прокрутка вверх при монтировании компонента
//   }, []);
  return (
    <Container fluid className='eventwrapper' style={{paddingRight: 0, paddingLeft: 0}}>
        <Container fluid-xs className='cardwrapper'>
            <div className='eventtitle'>
            <h2 >{title}</h2>
            <p>{date}</p>
            </div>
            <div className="image-container">
                <img src={img} alt="" />
             </div>
            <Link to={`/event/${id}`}>Читать далее...</Link>
        </Container>
    </Container>
  );
};


