import React from 'react'
import { Container, Navbar,Nav } from 'react-bootstrap'
import './nav.css'
import logo from '../../assets/logo.png'

export const Menu=()=> {
  return (
        <Navbar expand="lg" className='navbarmain' data-bs-theme="dark">
          <Container>
            <Navbar.Brand href="/" className='d-flex align-items-center'>
              <img src={logo} alt="logo" style={{ maxWidth: '40px', maxHeight: '40px' }} />
              <p className='logotext'>ДК Цветлит</p>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className=" d-flex align-items-center" style={{display:'imline-block'}}>
                <Nav.Link href="/#/theater" className='navbarbtn'>Театр Эстрады "Арлекин"</Nav.Link>
                <Nav.Link href="/#/achivments" className='navbarbtn'>Наши достижения</Nav.Link>
                <Nav.Link href="/#/history" className='navbarbtn'>Наша история</Nav.Link>
                <Nav.Link href="https://zwetlit-grodno.by/formir/" className='navbarbtn'>Жизнь предприятия</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
    
  )
}
