import React,{useEffect} from 'react';
import { Container, Row, Col, Table, ListGroup } from 'react-bootstrap';

import { Menu } from '../../components/Navbar/Navbar';
import { Contacs } from '../../components/Contacts/Contacts';

import './clubs.css'

export const Clubs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка вверх при монтировании компонента
  }, []);
  return (
    <Container fluid className='clubswrapper' style={{paddingRight: 0, paddingLeft: 0}}>
      <Menu/>
      <Container fluid-xs  className='tablewrapper' style={{flexDirection:'column'}}>
      <h1 className="text-center mb-4">Добро пожаловать в ДК Унитарного Предприятия «Цветлит»</h1>
      <p>Придя в наш Дом культуры, вы можете ознакомиться с работой, а при сильном желании и принять участие в деятельности следующих коллективов любительского творчества:</p>
      
      <h2 className="mt-4">Хореографическая группа народного любительского коллектива Республики Беларусь «Театр эстрады «Арлекин»»</h2>
      <Table striped bordered hover className="mb-4">
        <thead>
          <tr>
            <th>День</th>
            <th>Время</th>
          </tr>
        </thead>
        <tbody>
          <tr><td>Понедельник</td><td>15:30 - 17:30</td></tr>
          <tr><td>Вторник</td><td>15:30 - 17:45</td></tr>
          <tr><td>Среда</td><td>15:30 - 17:45</td></tr>
          <tr><td>Четверг</td><td>15:30 - 17:45</td></tr>
          <tr><td>Суббота</td><td>16:00 - 19:30</td></tr>
        </tbody>
      </Table>

      <h2>Любительский танцевальный коллектив «Гармония»</h2>
      <p>На платной основе согласно договора об оказании танцевальных услуг на условиях публичной оферты. Стоимость составляет 30 руб., за восемь занятий.</p>
      <Table striped bordered hover className="mb-4">
        <thead>
          <tr>
            <th>День</th>
            <th>Время</th>
          </tr>
        </thead>
        <tbody>
          <tr><td>Понедельник</td><td>17:30 - 18:30</td></tr>
          <tr><td>Вторник</td><td>17:45 - 18:45</td></tr>
          <tr><td>Среда</td><td>17:30 - 18:30</td></tr>
          <tr><td>Четверг</td><td>17:45 - 18:45</td></tr>
          <tr><td>Суббота</td><td>14:30 - 15:30</td></tr>
        </tbody>
      </Table>

      <h2>Народный любительский коллектив Республики Беларусь «Театр эстрады «Арлекин»»</h2>
      <Table striped bordered hover className="mb-4">
        <thead>
          <tr>
            <th>День</th>
            <th>Время</th>
          </tr>
        </thead>
        <tbody>
          <tr><td>Понедельник</td><td>16:00 - 18:00</td></tr>
          <tr><td>Пятница</td><td>15:00 - 17:00</td></tr>
        </tbody>
      </Table>

      <h2>Детский любительский коллектив игрового творчества «Игровичок»</h2>
      <Table striped bordered hover className="mb-4">
        <thead>
          <tr>
            <th>День</th>
            <th>Время</th>
          </tr>
        </thead>
        <tbody>
          <tr><td>Среда</td><td>15:00 - 16:00</td></tr>
        </tbody>
      </Table>

      <p>Кроме этого, вы можете стать членом одного из предлагаемых любительских объединений по интересам:</p>
      <ListGroup className="mb-4" style={{fontSize:'18px'}}>
        <ListGroup.Item>Клуб «Пенсионер» - для людей, которые находятся на заслуженном отдыхе</ListGroup.Item>
        <ListGroup.Item>Клуб «Почемучек» - для детей младшего школьного возраста</ListGroup.Item>
        <ListGroup.Item>Клуб «Духовность» - для творческой и активной молодежи</ListGroup.Item>
        <ListGroup.Item>Клуб «Рыболов» - для любителей рыбалки</ListGroup.Item>
        <ListGroup.Item>Клуб «Дачник» - для садоводов-огородников</ListGroup.Item>
        <ListGroup.Item>Клуб «Говорящие руки» - для любителей жестового языка</ListGroup.Item>
      </ListGroup>

      <h2 className="mt-4">Спортивные секции</h2>
      <p>Занятия в группах проводят профессиональные тренеры. Расписание:</p>

      <h3>Спортивная группа по волейболу</h3>
      <Table striped bordered hover className="mb-4">
        <thead>
          <tr>
            <th>День</th>
            <th>Время</th>
          </tr>
        </thead>
        <tbody>
          <tr><td>Понедельник</td><td>16:00 - 18:00</td></tr>
          <tr><td>Пятница</td><td>15:00 - 18:00</td></tr>
        </tbody>
      </Table>

      <h3>Группа "Здоровье" по плаванию</h3>
      <Table striped bordered hover className="mb-4">
        <thead>
          <tr>
            <th>День</th>
            <th>Время</th>
          </tr>
        </thead>
        <tbody>
          <tr><td>Понедельник</td><td>18:00 - 20:00</td></tr>
        </tbody>
      </Table>

      <p>Для получения подробной информации вы можете позвонить по следующим контактным телефонам:</p>
      <ul className="mb-4">
        <li>+375-152-55-01-40 – рабочий телефон</li>
        <li>+375-44-550-41-52 – рабочий телефон</li>
        <li>+375-152-55-01-67 – рабочий телефон</li>
      </ul>
      <p className="text-center mt-4">Будем рады видеть вас в рядах артистов и спортсменов нашего ДК!</p>
      </Container>
      <Contacs/>
    </Container>
  );
};


