import React,{useEffect,useState} from 'react';
import { Container, Row, Col, Table, ListGroup,Carousel,CarouselItem } from 'react-bootstrap';

import { Menu } from '../../components/Navbar/Navbar';
import { Contacs } from '../../components/Contacts/Contacts';

import './achivmentspage.css'

import diplom1 from '../../assets/diplom1.jpg'
import diplom2 from '../../assets/diplom2.jpg'
import diplom3 from '../../assets/diplom3.jpg'
import diplom4 from '../../assets/diplom4.jpg'
import diplom5 from '../../assets/diplom5.jpg'
import diplom6 from '../../assets/diplom6.jpg'
import diplom7 from '../../assets/diplom7.jpg'
import diplom8 from '../../assets/diplom8.jpg'


export const AhivmentsPage = () => {
    const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка вверх при монтировании компонента
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <Container fluid className='achivwrapper'>
        <Menu/>
            <Container fluid-xs className='achivwrapper2'>
                <h2 className='achivtitle'>Наши Достижения</h2>
                <div className='achivtext1'>Дом культуры предприятия "Цветлит" на протяжении многих лет является важным центром культурной и спортивной жизни нашего города и предприятия. Это место, где рождаются творческие идеи, формируются традиции и укрепляется дух коллектива. Здесь каждый найдет что-то по душе: от театрального и вокального искусства до активных занятий спортом. Наш Дом культуры стал платформой для множества мероприятий и проектов, которые получили широкое признание как на региональном, так и на всебелорусском уровне.</div>
                <div className='achivtext2'>Кроме творческих коллективов, Дом культуры предлагает широкий выбор спортивных секций: волейбол, шахматы, фитнес и многое другое. Это делает наш Дом культуры настоящим центром, где каждый может развиваться не только творчески, но и физически.</div>
                <div className='achivtext3'>Присоединяйтесь к команде нашего Дома культуры, где традиции и новаторство объединяются для достижения новых вершин!</div>
                <Row className="mt-4" >
                    <Col xs={12} sm={6} md={3} className="mb-3 p-2" >
                        <img src={diplom1} onClick={() => handleImageClick(diplom1)} alt="Image 1" className="img-fluid" />
                    </Col>
                    <Col xs={12} sm={6} md={3} className="mb-3 p-2" >
                        <img src={diplom2} onClick={() => handleImageClick(diplom2)} alt="Image 2" className="img-fluid" />
                    </Col>
                    <Col xs={12} sm={6} md={3} className="mb-3 p-2" >
                        <img src={diplom3} onClick={() => handleImageClick(diplom3)} alt="Image 3" className="img-fluid" />
                    </Col>
                    <Col xs={12} sm={6} md={3} className="mb-3 p-2" >
                        <img src={diplom4} onClick={() => handleImageClick(diplom4)} alt="Image 4" className="img-fluid" />
                    </Col>
                    <Col xs={12} sm={6} md={3} className="mb-3 p-2" >
                        <img src={diplom5} onClick={() => handleImageClick(diplom5)} alt="Image 5" className="img-fluid" />
                    </Col>
                    <Col xs={12} sm={6} md={3} className="mb-3 p-2" >
                        <img src={diplom6} onClick={() => handleImageClick(diplom6)} alt="Image 6" className="img-fluid" />
                    </Col>
                    <Col xs={12} sm={6} md={3} className="mb-3 p-2" >
                        <img src={diplom7} onClick={() => handleImageClick(diplom7)} alt="Image 7" className="img-fluid" />
                    </Col>
                    <Col xs={12} sm={6} md={3} className="mb-3 p-2" >
                        <img src={diplom8} onClick={() => handleImageClick(diplom8)} alt="Image 8" className="img-fluid" />
                    </Col>
                </Row>
            </Container>
            {selectedImage && (
          <div className="modal" onClick={handleCloseModal}>
            <span className="close">&times;</span>
            <img className="modal-content" src={selectedImage} alt="Увеличенное изображение" />
          </div>
        )}
        <Contacs/>
    </Container>
  );
};


