import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import './eventpage.css';
import { Menu } from '../../components/Navbar/Navbar';
import { Contacs } from '../../components/Contacts/Contacts';

export const EventPage = () => {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    // Обработка ошибки и отладка
    const fetchData = async () => {
        try {
            const response = await fetch(`https://zwetlit-grodno.by/dk_api/api/v1/news/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            setIsLoaded(true);
            setData(result);

        } catch (error) {
            console.error('Fetch error:', error);
            setIsLoaded(true);
            setError(error);
        }
    };

    fetchData();
}, [id]);
  console.log(data)

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  // Collect images from the data object
  const photos = [
    data.image1,
    data.image2,
    data.image3,
    data.image4,
    data.image5,
    data.image6,
    data.image7,
    data.image8,
    data.image9,
  ].filter(Boolean);

  const photosToShow = showMore ? photos : photos.slice(0, 6);

  return (
    <Container fluid className='eventpagewrapper' style={{ paddingRight: 0, paddingLeft: 0 }}>
      <Menu />
      <Container fluid-xs className="eventpagewrapper2">
        <div className='eventpagetitle'>
          <h2>{data.title}</h2>
          <p>{data.date}</p>
        </div>
        <img className='eventpageimg' src={data.thumbnail} alt="No photo..." />
        {data.first_text && <p className='eventpagetext'>{data.first_text}</p>}
        {data.second_text && <p className='eventpagetext'>{data.second_text}</p>}
        {data.third_text && <p className='eventpagetext'>{data.third_text}</p>}
        {data.fourth_text && <p className='eventpagetext'>{data.fourth_text}</p>}

        <div className="eventpagephotos">
          {photosToShow.map((photo, index) => (
            <img
              key={index}
              src={photo}
              alt={`Фото ${index + 1}`}
              className="eventpagephoto"
              onClick={() => handleImageClick(photo)}
              style={{ cursor: 'pointer' }}
            />
          ))}
        </div>
        {photos.length > 6 && (
          <div className="show-more-container">
            <button
              className="show-more-button"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? 'Свернуть' : 'Показать больше'}
            </button>
          </div>
        )}
        {selectedImage && (
          <div className="modal" onClick={handleCloseModal}>
            <span className="close">&times;</span>
            <img className="modal-content" src={selectedImage} alt="Увеличенное изображение" />
          </div>
        )}
      </Container>
      <Contacs />
    </Container>
  );
};
