import React,{useEffect,useState} from 'react';
import { Container, Row, Col} from 'react-bootstrap';

import { Menu } from '../../components/Navbar/Navbar';
import { Contacs } from '../../components/Contacts/Contacts';

import './historypage.css'




export const HistoryPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка вверх при монтировании компонента
  }, []);

  

  return (
    <Container fluid className='histwrapper'>
        <Menu/>
            <Container fluid-xs className='histwrapper2'>
                <h2 className='histtitle'>История Дома Культуры</h2>
            </Container>
        <Contacs/>
    </Container>
  );
};


