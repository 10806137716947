import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import './yearpage.css';
import { Menu } from '../../components/Navbar/Navbar';
import { Contacs } from '../../components/Contacts/Contacts';
import { EventCard } from '../../components/EventCard/EventCard';

export const YearPage = () => {
  const { year } = useParams();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetch(`https://zwetlit-grodno.by/dk_api/api/v1/news/?year=${year}`, {method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }})
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);

          // Фильтрация и сортировка данных
          const filteredAndSortedData = result
            .filter(item => {
              // Здесь можно добавить дополнительные условия фильтрации, если необходимо
              return true; // Пока что фильтр пропускает все элементы
            })
            .sort((a, b) => new Date(b.date) - new Date(a.date)); // Сортировка по убыванию даты

          setData(filteredAndSortedData);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [year]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      window.scrollTo(0, 0);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      window.scrollTo(0, 0);
    }
  };

  // if (error) {
  //   return <div>Error: {error.message}</div>;
  // } else if (!isLoaded) {
  //   return <div>Loading...</div>;
  // } else {
    return (
    <Container fluid className='yearwrapper' style={{ paddingRight: 0, paddingLeft: 0 }}>
      <Menu />
      <Container fluid-xs className='yearwrapper2'>
        <h2 className='yeartitle'>Мероприятия {year}</h2>
        {currentItems.length > 0 ? (
          currentItems.map((item, index) => (
            <EventCard
              key={index}
              title={item.title}
              img={item.thumbnail}
              id={item.id}
              date={item.date}
            />
          ))
        ) : (
          <p style={{ fontSize: '36px', fontWeight: '600', marginTop: '50px' }}>
            Нет информации за этот год
          </p>
        )}
      </Container>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
        {"<"}
        </button>
        <span>
          Страница {currentPage} из {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          {">"}
        </button>
      </div>

      <Contacs />
    </Container>
  );
};
