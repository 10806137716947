
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {Home} from './pages/home/home.jsx'
import { Clubs } from './pages/clubs/clubs.jsx';
import { Theater } from './pages/TheaterPage/TheaterPage.jsx';
import { YearPage } from './pages/YearPage/YearPage.jsx';
import { EventPage } from './pages/EventPage/EventPage.jsx';
import { AhivmentsPage } from './pages/AchivmentsPage/AchivmentsPage.jsx';
import { HistoryPage } from './pages/HistoryPage/HistoryPage.jsx';
import {createBrowserRouter,RouterProvider,createHashRouter} from "react-router-dom";



const router = createHashRouter(
  [
  {
    path: '/',
    element: <Home/>,
  },
  {
    path:'/clubs',
    element:<Clubs/>
  },
  {
    path:'/theater',
    element:<Theater/>
  },
  {
    path:'/year/:year',
    element:<YearPage/>
  },
  {
    path:'/achivments',
    element:<AhivmentsPage/>
  },
  {
    path:'/history',
    element:<HistoryPage/>
  },
  {
    path:'/event/:id',
    element:<EventPage/>
  }],
  {
    basename:'',
  }
);

function App() {
  return (
    <>
    <RouterProvider router={router} />
    </>
    );
}

export default App;
