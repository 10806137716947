import React from 'react'
import { Container,Row,Col, } from 'react-bootstrap'
import './contacts.css'

import icon from '../../assets/telephone.png'


export const Contacs=()=> {
  return (
    <Container fluid className="contactmenu d-flex align-items-center justify-content-center flex-column">
      <h2 className="clubsname text-center" id='contacts'>Контакты</h2>
      <Row className="w-100 d-flex align-items-center justify-content-between">
        <Col xs={12} md={4} className="mb-3 mb-md-0 text-center text-md-start">
          <div className="contactmenutext">
            <p>Адрес: 230008, г.Гродно ул. Дзержинского, 41/1</p>
            <p>Телефон: +375 152 55-01-40</p>
          </div>
        </Col>
        <Col xs={12} md={4} className="mb-3 mb-md-0 text-center text-md-start">
          <div className="contactmenutext">
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}><img src={icon} alt="" style={{maxWidth:'20px',maxHeight:'20px',marginRight:'5px'}}/><p>+375-152-55-01-40</p></div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}><img src={icon} alt="" style={{maxWidth:'20px',maxHeight:'20px',marginRight:'5px'}}/><p>+375-44-550-41-52</p></div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}><img src={icon} alt="" style={{maxWidth:'20px',maxHeight:'20px',marginRight:'5px'}}/><p>+375-152-55-01-67</p></div>
          </div>
        </Col>
        <Col xs={12} md={4} className="text-center text-md-left nowrap">
          <p>Директор ДК – Воронко Зоя Михайловна</p>
          <p>Культорганизатор ДК – Маркевич Надежда Васильевна</p>
          <p>Cтарший инструктор методист по спорту – Сакута Любовь Валентиновна</p>
          <p>Руководитель хореографического коллектива – Ропейко Инна Ивановна</p>
        </Col>
      </Row>
  </Container>
  )
}
