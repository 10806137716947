import React,{useState,useEffect} from 'react'
import { Container, Navbar,Nav,Card } from 'react-bootstrap'
import './home.css'
import { Menu } from '../../components/Navbar/Navbar'
import { TypeCard } from '../../components/TypeCard/TypeCard'
import { Contacs } from '../../components/Contacts/Contacts'
import { useNavigate } from 'react-router-dom'

import ph1 from '../../assets/galavnaya_cvetlit.png'
import card1 from '../../assets/dahce.png'
import card2 from '../../assets/book.png'
import card3 from '../../assets/sports.png'
import card4 from '../../assets/theatr.png'

export const Home=()=> {
  const [error,setError]=useState(null);
  const [loaded,setIsLoaded]=useState(false);
  const [yearsArray, setYearsArray] = useState([]);

  useEffect(() => {
    fetch('https://zwetlit-grodno.by/dk_api/api/v1/news-exists/', {method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }})
    
      .then((res) => res.json())
      .then(
        (result) => {
          const existence = result.existence;
          const filteredYears = [];

          for (let year in existence) {
            if (existence[year]) {
              filteredYears.push(parseInt(year));
            }
          }

          setYearsArray(filteredYears);
        },
        (error) => {
          // обработка ошибки
        }
      );
  }, []);
  const navigate = useNavigate();

  const handleNavigate = (year) => {
    navigate(`/year/${year}`);
  };
  return (
    <Container fluid className='mainwrapper'>
    <Menu></Menu>
      <img className='mainimg' src={ph1} alt=''></img>
      <p className='widestrip'>f</p>
      <div className='introtext'>
          Добро пожаловать на сайт Дома культуры предприятия "Цветлит"! Мы гордимся тем, что наша организация является домом для талантливых людей с ограниченными возможностями слуха.<br></br> Здесь каждый находит поддержку и возможность раскрыть свои творческие способности. Наш Дом культуры предлагает разнообразные программы, мероприятия и кружки, где каждый может проявить себя, независимо от особенностей слуха. Присоединяйтесь к нам, и давайте вместе создавать мир, наполненный вдохновением, радостью и взаимопониманием!
        </div>
      <Container fluid-xs className='d-flex btngroupcontainer' style={{width:'100%'}}>
        <Container className='vidcontainer'>
        <div className='videotext'>Наша работа</div>
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/QrjX0F1faPM?si=eUtdID6iTEmnRE4J" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <div>
        <div className='videotext'>Cтудии, Клубы, Объединения</div>
        <div className='card-container'>
        <TypeCard  img={card1} btntitle={'Танцы'}/>
        <TypeCard  img={card2} btntitle={'Клубы'}/>
        <TypeCard  img={card3} btntitle={'Спорт'}/>
        <TypeCard  img={card4} btntitle={'Театр'}/>
      </div>
        </div>
        </Container>
        <Container fluid className='btngroup' style={{maxWidth:'500px',height:'97%'}}>
        <div className='videotext2'>Мероприятия</div>
        {yearsArray.map((year) => (
        <div className="container" key={year}>
          <a href={`/#/year/${year}`} className="btnbottom">Мероприятия {year}</a>
        </div>
      ))}
        {/* <div class="container">
          <a href="/year/2018"  class="btnbottom">Мероприятия 2018</a>
        </div>
        <div class="container">
          <a href="/year/2019" class="btnbottom">Мероприятия 2019</a>
        </div>
        <div class="container">
          <a href="/year/2020" class="btnbottom">Мероприятия 2020</a>
        </div>
        <div class="container">
          <a href="/year/2021" class="btnbottom">Мероприятия 2021</a>
        </div>
        <div class="container">
          <a href="/year/2022" class="btnbottom">Мероприятия 2022</a>
        </div>
        <div class="container">
          <a href="/year/2023" class="btnbottom">Мероприятия 2023</a>
        </div>
        <div class="container">
          <a href="/year/2024" class="btnbottom">Мероприятия 2024</a>
        </div> */}
        </Container>
      </Container>
      <Contacs></Contacs>
    </Container>
  )
}