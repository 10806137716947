import React from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';


export const TypeCard=({img,btntitle})=> {

  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate('/clubs'); // Укажите нужный путь
  };
  return (
    <Card
          style={{ width: '370px',overflow: 'hidden',cursor:'pointer' }}
          className="mb-2"
          onClick={handleCardClick}
        >
          <Card.Body style={{padding:'0'}}>
          <img 
          style={{ 
            objectFit: 'cover', 
            width: '100%', 
            height: 'auto',
            transition: 'transform 0.3s ease-in-out' // Плавный переход для увеличения
          }} 
           src={img} alt="..."  className='cardimg' />
          
          <button
          style={{ 
            position: 'absolute', 
            bottom: '10px', 
            left: '20%', 
            transform: 'translateX(-50%)', 
            backgroundColor: 'aliceblue', 
            opacity:'80%',
            color: 'black', 
            border: 'none', 
            fontSize:'18px',
            padding: '5px 30px', 
            cursor: 'pointer',
          }}
          >{btntitle}</button>
          </Card.Body>
        </Card>
  )
}