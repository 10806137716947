import React,{useEffect} from 'react';
import { Container, Row, Col, Table, ListGroup,Carousel,CarouselItem } from 'react-bootstrap';

import { Menu } from '../../components/Navbar/Navbar';
import { Contacs } from '../../components/Contacts/Contacts';

import './theaterpage.css'

import arlekin1 from '../../assets/arlekin1 (1).jpg'
import arlekin2 from '../../assets/arlekin2 (1).jpg'
import arlekin3 from '../../assets/arlekin3 (1).jpg'
import arlekin4 from '../../assets/arlekin4 (1).jpg'

export const Theater = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка вверх при монтировании компонента
  }, []);
  return (
    <Container fluid className='theatrewrapper' style={{paddingRight: 0, paddingLeft: 0}}>
      <Menu/>
      <Container className='theatrecarousel' fluid-xs>
        <h2 className='theatretitle'>Театр эстрады "Арлекин"</h2>
  <Carousel style={{ maxWidth: '1100px', maxHeight: 'auto' ,marginTop:'20px'}}>
    <Carousel.Item>
      <img src={arlekin1} alt="arl1" style={{ maxWidth: '100%', maxHeight: '600px', objectFit: 'cover' }} />
    </Carousel.Item>
    <Carousel.Item>
      <img src={arlekin2} alt="arl2" style={{ maxWidth: '100%', maxHeight: '600px', objectFit: 'cover' }} />
    </Carousel.Item>
    <Carousel.Item>
      <img src={arlekin3} alt="arl3" style={{ maxWidth: '100%', maxHeight: '600px', objectFit: 'cover' }} />
    </Carousel.Item>
    <Carousel.Item>
      <img src={arlekin4} alt="arl4" style={{ maxWidth: '100%', maxHeight: '600px', objectFit: 'cover' }} />
    </Carousel.Item>
  </Carousel>
  <p className='theatretext'>Дом культуры унитарного предприятия «Цветлит» и Белорусское общество глухих рады сообщить, что готовы украсить ваши культурно-массовые мероприятия выступлениями народного любительского коллектива театра эстрады «Арлекин». Наш коллектив с радостью примет участие в ваших событиях, добавив им яркости и незабываемых впечатлений.</p>
</Container>
      <Contacs/>
    </Container>
  );
};


